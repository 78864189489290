export const environment = {
    production: true,
    dealcoreBaseUrl: 'https://dealcore.hyundai-partners.de',
    cloudinaryBaseUrl: 'https://res.cloudinary.com/hmd-pw-dfnosvs4u',
    showroomScriptUrl: 'https://showroom-scripts.hyundai.de/integration/integration.js',
    preloading: true,
    trackingTerm: 'Production',
    redirect: true,
    logs: false,
};
